import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeC from '../components/Heroes/HeroTypeC';
import BannerInfoTypeB from '../components/Banners/BannerInfoTypeB';
import BannerTypeA from '../components/Banners/BannerTypeA';
import Reviews from '../components/Reviews/Review';
import BannerInfoTypeA from '../components/Banners/BannerInfoTypeA';
import TripleBanner from '../components/Location/TripleBanner';
import MultiArticleV2 from '../components/Articles/MultiArticleV2';
import FaqSection from '../components/FAQ/FaqSection';
import SEO from '../components/SEO/SEO';

export default function Keswick({ data }) {
  const hero_type_c = data.strapiKeswickAbout.Hero;
  const info_banner_type_b = data.strapiKeswickAbout.InfoBannerB;
  //   const banner_type_middle = data.strapiKeswickAbout.Blog_Banner_Middle;
  const reviews = data.strapiKeswickAbout.Reviews;
  const info_banner_type_a = data.strapiKeswickAbout.InfoBannerA;
  const triple_banner = data.strapiKeswickAbout.TripleBanner;
  const multi_article = data.strapiKeswickAbout.MultiArticle;
  const faq = data.strapiKeswickAbout.Faq;
  const banner_type_bottom = data.strapiKeswickAbout.BlogBanner;

  return (
    <>
      <SEO
        title="Keswick’s Favourite Weed Dispensary | House of Cannabis"
        description="Visit out cannabis store in Kingston, and you’ll find 
            the city’s best selection of cannabis flowers and weed accessories. 
            Not sure what you want? We can help!"
      />
      <HeroTypeC
        title={hero_type_c.Title}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />
      <BannerInfoTypeB
        description={info_banner_type_b.Content}
        bgImage={
          info_banner_type_b.BackgroundImage.localFile.childImageSharp
            .gatsbyImageData
        }
        buttonLabel={info_banner_type_b.Button.Label}
        buttonLink={info_banner_type_b.Button.Custom_Link_Slug}
        center
      />
      {/* <BannerTypeA 
          title={banner_type_middle.Title}
          description={banner_type_middle.Content}
          bannerImage={banner_type_middle.Background_Image.localFile.childImageSharp.gatsbyImageData}
          buttonLabel={'READ MORE'}
          link={'#'}
      /> */}
      {/* <Reviews 
          title={reviews.Title}
          reviewsdata={reviews.Single_Review}
      /> */}
      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />
      <TripleBanner
        title={triple_banner.Title}
        banners={triple_banner.Single_Triple_Info_Banner}
      />
      <MultiArticleV2
        leftarticle={multi_article.Left_Mutli_Article}
        rightarticle={multi_article.Right_Multi_Article}
      />
      <FaqSection faqdata={faq.FAQs} title={faq.Title} />
      <BannerTypeA
        title={banner_type_bottom.Article_Link.Title}
        description={banner_type_bottom.Article_Link.Preview_Text}
        bannerImage={
          banner_type_bottom.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />
    </>
  );
}

/* {`/blog/${banner_type_bottom.Article_Link.slug}`} */

export const query = graphql`
  query {
    strapiKeswickAbout {
      Hero {
        Content
        Title
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
      InfoBannerA {
        Content
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
      InfoBannerB {
        Content
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
        Button {
          Label
          Custom_Link_Slug
        }
      }
      MultiArticle {
        Left_Mutli_Article {
          article {
            Title
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
        }
        Right_Multi_Article {
          article {
            Title
          }
        }
      }
      TripleBanner {
        Single_Triple_Info_Banner {
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
          Content
          Header
        }
      }
      Faq {
        Title
        id
        FAQs {
          Question
          Answer
        }
      }

      BlogBanner {
        Article_Link {
          slug
          Title
          Preview_Text
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
  }
`;
